import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    Link,
    useShowController,
    SelectColumnsButton,
    BooleanInput,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField, ReferenceField, SimpleList} from "react-admin";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import React from "react";
import {Box, Breadcrumbs, Button, Grid, Typography, useMediaQuery} from "@mui/material";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {SendTaxpayerEmailNotificationButton} from "../../User/IVES/Components/SendTaxpayerEmailNotificationButton";
import {WithdrawButton} from "../../User/IVES/Components/WithdrawButton";
import {renderYearsForListFromRecord, renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";
import {getDatagridRowTextColor} from "../../../Components/Functions/DatagridRowSx";
import {Accordion, AccordionDetails, AccordionSummary} from "../../../Components/Accordion";
import {CustomTab, CustomTabPanel, CustomTabProps, CustomTabs} from "../../../Components/Tab";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {TaxpayerEmailNotifications} from "../../../Components/IvesRequestTaxpayerEmailNotifications";

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var record.expanded_ives_request_form **/
/** @var record.expanded_ives_request_form.name **/
/** @var record.expanded_ives_request_status **/
/** @var record.expanded_ives_request_status.name **/
/** @var ives_request.ives_request_status **/
/** @var ives_request.ives_request_status.name **/

const ListActions = ({ isSmall }) => {
    if (isSmall) {
        return false;
    }

    return (
        <TopToolbar>
            <SelectColumnsButton />
        </TopToolbar>
    );
}

export const ExpandedIvesRequestList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={filters}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="Expanded IVES Requests" />}
            resource="expanded-ives-requests"
            sort={{ field: 'updated_at', order: 'DESC' }}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={record => record.user.branch.institution.short_name}
                    tertiaryText={record => record.updated_at}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show">
                    <TextField source="id" label="ID" />
                    <TextField source="user.branch.institution.short_name" label="Institution" sortable={false} />
                    <TextField source="user.branch.name" label="Branch" sortable={false} />
                    <TextField source="user.name" label="User" sortable={false} />
                    <FunctionField source="ives_client.name" label="Client" sortable={false} render={(record) => record.ives_client.name.substring(0,20)} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" />
                    <TextField source="expanded_ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsForListFromRecord} label="Years" />
                    <TextField source="expanded_ives_request_status.name" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" />
                    <DateField showTime={true} source="updated_at" />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const lastFour = record.taxpayer_ssn.substring(5, 9);
    const form = record.expanded_ives_request_form.name === "ALL WAGE AND INCOME (ALL W2 and ALL 1099)" ? "ALL" : record.expanded_ives_request_form.name;
    const status = record.expanded_ives_request_status.name;
    const yearCount = record.expanded_ives_request_years.length;
    let years = yearCount + ' yr';
    if (yearCount > 1) { years += 's';}

    const rowColor = getDatagridRowTextColor(record);

    return (
        <Typography variant="body2" sx={{ color: rowColor}}>
            {lastFour + " - " + form + " - " + years}
            {" - " + status}
        </Typography>
    );
};

function shouldShowTetitos() {
    const user = JSON.parse(localStorage.getItem("user")) || {id: -1};

    return user.id === 1;
}

const filters = [
    <SearchInput placeholder="SSN" source="taxpayer_ssn" name="taxpayer_ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
    <IvesRequestFormFilterSelectInput source="expanded_ives_request_form_id" label="Form" alwaysOn={true} key={Math.random()} />,
    <IvesRequestStatusFilterSelectInput source="expanded_ives_request_status_id" label="Status" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
    <BooleanInput label="Hide Failed" alwaysOn={true} source="should_hide_failed" name="should_hide_failed" sx={{ padding: "12px" }} />,
    <BooleanInput label="Hide Expired" alwaysOn={true} source="should_hide_expired" name="should_hide_expired" sx={{ padding: "12px" }} />,
    shouldShowTetitos() ? <BooleanInput label="Tetitos Only" alwaysOn={true} source="should_show_only_tetitos" name="should_show_only_tetitos" sx={{ padding: "12px" }} /> : <></>
];

export const ExpandedIvesRequestShow = () => {
    const record = useShowController()?.record;
    const { isFetching } = useShowController();

    const ShowWrapper = ({ children }) => (
        <Box sx={{ width: "100%" }}>
            {children}
        </Box>
    );

    const showResults = !(isFetching || !record || record.expanded_ives_request_status.name !== 'Complete');

    return (
        <Show title={<ShowTitle/>} actions={<ShowActions/>} component={ShowWrapper}>
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                        <Grid item xl={12} lg={12} xs={12}>
                            <Item>
                                <SimpleShowLayout>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                        <ReferenceField source="user.branch.institution_id" reference="institutions" label="Institution" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="user.branch_id" reference="branches" label="Branch" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="user.id" reference="users" label="User" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <IvesRequestReferenceFields />
                                    </Breadcrumbs>
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Client</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="ives_client.name" label="Name"/>
                                    <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                                    <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Request</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <FunctionField render={renderSsnFromRecord} label="SSN" />
                                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" />
                                    <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                                    {(record?.taxpayer_previous_street_name) ? (
                                        <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                                    ) : (<></>)}
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <TaxpayerEmailNotifications />
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Status</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="expanded_ives_request_type.name" label="Type" />
                                    <FunctionField render={renderFormFromRecord} label="Form" />
                                    <FunctionField render={renderYearsFromRecord} label="Years" />
                                    <TextField source="expanded_ives_request_status.name" label="Status"/>
                                    <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                                    <DateField showTime={true} source="created_at"/>
                                    <DateField showTime={true} source="became_invoiceable_at"/>
                                    <DateField showTime={true} source="updated_at"/>
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!showResults} defaultExpanded={showResults}>
                <AccordionSummary >
                    <Typography>Results</Typography>
                </AccordionSummary>
                {
                    (showResults) ? (
                        <AccordionDetails>
                            <IvesResults />
                        </AccordionDetails>
                    ) : (<></>)
                }
            </Accordion>
        </Show>
    );
};

const IvesResults = () => {
    const record = useRecordContext();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const pdfFilename = 'result.pdf';
    const pdfString = record.expanded_ives_request_results_merged_pdf;

    const htmlFilename = 'result.html';
    const htmlString = btoa(record.expanded_ives_request_results_merged_html);

    return (
        <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            <CustomTabs value={value} onChange={handleChange} >
                <CustomTab label="Report" {...CustomTabProps(0)} />
                <CustomTab label="Downloads" {...CustomTabProps(1)} />
            </CustomTabs>
            <Box>
                <CustomTabPanel value={value} index={0} >
                    <MergedIvesResultView />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <a download={pdfFilename} href={`data:application/pdf;base64,${pdfString}`} ><Button variant="outlined">Download PDF</Button></a><br/><br/>
                    <a download={htmlFilename} href={`data:text/plain;base64,${htmlString}`} ><Button variant="outlined">Download HTML</Button></a><br/><br/>
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

const MergedIvesResultView = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const {expanded_ives_request_results_merged_pdf} = record;

    if (!expanded_ives_request_results_merged_pdf) {
        return '';
    }

    return (
        <Item>
            <embed
                key={Math.random()}
                style={{ height: 500, overflow: 'scroll' }}
                src={`data:application/pdf;base64,${expanded_ives_request_results_merged_pdf}`}
                type="application/pdf" width="100%"
            ></embed>
        </Item>
    );
}

const IvesRequestReferenceFields = () => {
    const record = useRecordContext();

    if (record ? record.ives_requests : undefined) {
        const referenceFields = record.ives_requests.map(ives_request => {
            const link = "/ives-requests/" + ives_request.id + "/show";
            return (
                <Grid item xl={12} lg={12} xs={12}>
                    <Link variant="body2" key={Math.random()} to={link} >
                        {ives_request.id} - {ives_request.ives_request_status.name}
                    </Link>
                </Grid>
            )
        });

        return (
            <Grid container direction="column" justifyContent="space-evenly" alignItems="stretch" spacing={1}>
                {referenceFields}
            </Grid>
        );
    } else {
        return (<Typography variant="body2">None</Typography>);
    }
}

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'Expanded IVES Request #' + record.id;
}

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <WithdrawButton role="admin" type="expandedIvesRequest" />
        <SendTaxpayerEmailNotificationButton role="admin" type="expandedIvesRequest" />
    </TopToolbar>
);
import {ListButton, TopToolbar as RATopToolbar} from "react-admin";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import {Divider} from "@mui/material";
import {useSearchParams} from "react-router-dom";

export const TopToolbar = ({backButton = false, children, ...props}) => {
    const [searchParams] = useSearchParams();
    const backButtonResource = searchParams.get("bbr");

    return (
        <RATopToolbar {...props} >
            { backButton ? (
                <>
                    <ListButton resource={backButtonResource} label="Back" icon={<ArrowBackIosIcon />} />
                    <Divider />
                    <Divider />
                    <Divider />
                </>
                ) : (<></>) }
            {children}
            <div style={{ flex: 1}}></div>
        </RATopToolbar>
    );
};
import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    ReferenceField,
    NumberField,
    SelectInput,
    EditButton,
    NumberInput,
    useShowController,
    SearchInput, useRefresh, useRecordContext, useNotify,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions} from "../../../Components/Actions";
import React from "react";
import {Grid, Typography} from "@mui/material";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";
import {useMutation, useQuery} from "react-query";
import dataProvider from "../../../Providers/dataProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import ChecklistRtlIcon from "@mui/icons-material/ChecklistRtl";

export const PayoutList = () => {
    return (
        <List filters={postFilters} resource="payouts" sort={{ field: 'created_at', order: 'DESC' }} hasCreate={false} empty={false} exporter={false} >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="invoice.institution.name" label="Institution" />
                <TextField source="name" label="Name" />
                <TextField source="invoice.name" label="Invoice" />
                <TextField source="user.name" label="Affiliate User" />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <TextField source="payout_status.name" label="Status" />
                <DateField showTime={true} source="created_at" label="Created"  />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const PayoutStatusFilterSelectInput = (props) => {
    const { data, isSuccess, isLoading, isError} = useQuery(
        ['getPayoutStatuses'],
        () => dataProvider.getPayoutStatuses()
    );

    const payoutStatuses = data?.json;

    if (isLoading || isError || !payoutStatuses) {
        return <span key={Math.random()}>Loading...</span>
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={payoutStatuses}
                name="payout_status_id"
                source="payout_status_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};

const postFilters = [
    <SearchInput source="name" name="name" alwaysOn={true} key={Math.random()} />,
    <PayoutStatusFilterSelectInput label="Status" alwaysOn={true} key={Math.random()} />,
];

const MarkAsPaidButton = ({ disabled }) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const payoutId = record?.id;
    const notify = useNotify();

    const { mutate, isLoading } = useMutation(
        ['markPayoutAsPaid'],
        () => dataProvider.markPayoutAsPaid(payoutId),
        {
            onSuccess: () => {
                refresh();
            },
            onError: () => {
                notify('Could not mark payout as paid', { type: "error"});
            }
        }
    );

    if (!record) {
        return '';
    }

    const handleFormSubmit = () => {
        mutate();
    }

    return (
        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleFormSubmit}
            startIcon={<ChecklistRtlIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            Mark As Paid
        </LoadingButton>
    );
};

const PayoutShowActions = () => {
    const showController = useShowController();
    const showEditButton = showController?.record?.payout_status_id === 1;
    const showMarkAsPaidButton = showController?.record?.payout_status_id === 1;

    return (
        <TopToolbar backButton={true}>
            <EditButton disabled={!showEditButton} />
            <MarkAsPaidButton status="Sent" disabled={!showMarkAsPaidButton} />
        </TopToolbar>
    );
};

export const PayoutShow = () => {
    return (
        <Show title={<PageTitle />} actions={<PayoutShowActions />}>
            <SimpleShowLayout>
                <ReferenceField source="invoice.institution_id" reference="institutions" label="Institution" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="invoice_id" reference="invoices" label="Invoice" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="user_id" reference="users" label="Affiliate User" link="show">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="name"/>
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <TextField source="payout_status.name" label="Status" />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated" />
            </SimpleShowLayout>
        </Show>
    );
};

export const PayoutEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
        <SimpleForm>
            <Grid container>
                <Grid item xl={12}>
                    <Typography variant="caption">Institution:&nbsp;&nbsp;</Typography>
                    <ReferenceField source="invoice.institution_id" reference="institutions" label="Institution" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                </Grid>
                <Grid item xl={12}>
                    <Typography variant="caption">Invoice:&nbsp;&nbsp;</Typography>
                    <ReferenceField source="invoice_id" reference="invoices" label="Invoice" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                </Grid>
                <Grid item xl={12}>
                    <Typography variant="caption">Affiliate User:&nbsp;&nbsp;</Typography>
                    <ReferenceField source="user_id" reference="users" label="User" link="show">
                        <TextField source="name" />
                    </ReferenceField>
                </Grid>
                <Grid item xl={12}>
                    <TextInput name="name" source="name" style={{ width : '300px' }} />
                </Grid>
                <Grid item xl={12}>
                    <NumberInput name="amount" source="amount" required={true} style={{ width : '300px' }} />
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Grid, Paper} from "@mui/material";
import {LineChart} from "@mui/x-charts";
import React from "react";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {Button} from "ra-ui-materialui";
import dayjs from "dayjs";

export const IvesRequestsByDay = () => {
    const [startDate, setStartDate] = React.useState(dayjs().subtract(14, "days"));
    const [endDate, setEndDate] = React.useState(dayjs());
    const [months, setMonths] = React.useState([1]);
    const [created, setCreated] = React.useState([1]);
    const [failed, setFailed] = React.useState([1]);
    const [completed, setCompleted] = React.useState([1]);
    const [expired, setExpired] = React.useState([1]);

    const { mutate, isLoading} = useMutation(
        ['getIvesRequestsByDay'],
        () => dataProvider.getIvesRequestsByDay(dayjs(startDate).format('YYYY-MM-DD'), dayjs(endDate).format('YYYY-MM-DD')),
        {
            onSuccess: (data) => {
                const report = data?.json;
                setMonths(Object.keys(report.created).map((day) => {return day.substring(5)}));
                setCreated(Object.values(report.created));
                setFailed(Object.values(report.failed));
                setCompleted(Object.values(report.completed));
                setExpired(Object.values(report.expired));
            }
        }
    );

    const startDateChanged = (value) => {
        const newDate = dayjs(value.toString());
        setStartDate(newDate.format("YYYY-MM-DD"));
    }

    const endDateChanged = (value) => {
        const newDate = dayjs(value.toString());
        setEndDate(newDate.format("YYYY-MM-DD"));
    }

    const getReport = () => {
        mutate();
    }

    const colors = ['#1976d2', '#cc3939', 'green', 'black'];

    return (
        <Paper elevation={2}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid item >
                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xl={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Start"
                                    onChange={startDateChanged}
                                    defaultValue={dayjs().subtract(14, "days")}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xl={4}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="End"
                                    onChange={endDateChanged}
                                    defaultValue={dayjs()}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xl={4}>
                            <Button label="Apply" onClick={getReport} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={12}>
                    <LineChart
                        loading={isLoading}
                        width={1200}
                        height={300}
                        colors={colors}
                        series={[
                            {data: created, label: 'Created'},
                            {data: failed, label: 'Failed'},
                            {data: completed, label: 'Completed'},
                            {data: expired, label: 'Expired'},
                        ]}
                        xAxis={[{scaleType: 'point', data: months}]}
                    />
                </Grid>
            </Grid>
        </Paper>
)
}
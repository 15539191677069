import {Typography} from "@mui/material";
import React from "react";

export const renderFormFromRecord = (record) => {
    if (!record) return '';

    let name = '';

    /** @var record.request_form */
    /** @var record.ives_request_form */
    /** @var record.expanded_ives_request_form */
    if (record?.request_form) {
        name = record.request_form.id === 45 ? 'ALL' : record.request_form.name;
    } else if (record?.ives_request_form) {
        name = record.ives_request_form.id === 45 ? 'ALL' : record.ives_request_form.name;
    } else if (record?.expanded_ives_request_form) {
        name = record.expanded_ives_request_form.id === 45 ? 'ALL' : record.expanded_ives_request_form.name;
    }

    return (
        <Typography variant="body2">
            {name}
        </Typography>
    );
}
import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    SimpleList,
    ReferenceField,
    useShowController,
    SelectColumnsButton,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField } from "react-admin";
import React from "react";
import {Box, Grid, Typography, useMediaQuery} from "@mui/material";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {EmptyList} from "../../../Components/EmpyList";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {RequestStatusField} from "./Components/RequestStatusField";
import {renderYearsForListFromRecord, renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";
import {getDatagridRowTextColor} from "../../../Components/Functions/DatagridRowSx";
import {TaxpayerEmailNotifications} from "../../../Components/IvesRequestTaxpayerEmailNotifications";

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_street_name **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.taxpayer_previous_street_name **/
/** @var record.taxpayer_previous_city_name **/
/** @var record.taxpayer_previous_state_abbreviation **/
/** @var record.taxpayer_previous_zip **/
/** @var record.ives_request_status **/
/** @var record.ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.street_name **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var ives_request.ives_request_status **/
/** @var ives_request.ives_request_status.name **/

const ListActions = ({ isSmall }) => (
    <TopToolbar>
        {(isSmall) ? (<></>) : (<SelectColumnsButton />)}
    </TopToolbar>
);

export const AffiliateIvesRequestList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List
            filters={postFilters}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="IVES Requests" />}
            resource="affiliate/ives-requests"
            sort={{ field: 'created_at', order: 'DESC' }}
            empty={<EmptyList resourceLabel="IVES Requests" createButton={false} />}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={record => record.user.branch.institution.short_name}
                    tertiaryText={record => record.updated_at}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show" id='ives-request-table'>
                    <TextField source="id" label="ID" />
                    <TextField source="user.branch.institution.short_name" label="Institution" sortable={false} />
                    <TextField source="user.branch.name" label="Branch" sortable={false} />
                    <TextField source="user.name" label="User" sortable={false} />
                    <TextField source="ives_client.name" label="Client" sortable={false} />
                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" sortable={false} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" sortable={false} />
                    <TextField source="ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsForListFromRecord} label="Years" sortable={false} />
                    <TextField source="ives_request_status.name" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created" />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    /** @var record.ives_request_form **/
    /** @var record.ives_request_form.name **/
    const lastFour = record.taxpayer_ssn.substring(5, 9);
    const form = record.ives_request_form.name === "ALL WAGE AND INCOME (ALL W2 and ALL 1099)" ? "ALL" : record.ives_request_form.name;
    const status = record.ives_request_status.name;
    const yearCount = record.ives_request_years.length;
    let years = yearCount + ' yr';
    if (yearCount > 1) { years += 's';}

    const rowColor = getDatagridRowTextColor(record);

    return (
        <Typography variant="body2" sx={{ color: rowColor}}>
            {lastFour + " - " + form + " - " + years}
            {" - " + status}
        </Typography>
    );
};

const postFilters = [
    <SearchInput placeholder="SSN" source="taxpayer_ssn" name="taxpayer_ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
    <SearchInput placeholder={TrackingNumberStringForIndustry()} source="tracking_number" name="tracking_number" alwaysOn={true} key={Math.random()} />,
    <IvesRequestFormFilterSelectInput source="ives_request_form_id" label="FORM" alwaysOn={true} key={Math.random()} />,
    <IvesRequestStatusFilterSelectInput source="ives_request_status_id" label="STATUS" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} source="affiliate" />,
];

export const AffiliateIvesRequestShow = () => {
    const record = useShowController()?.record;

    return (
        <Show title={<ShowTitle />} actions={<ShowActions />} resource='affiliate/ives-requests' >
            <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Client</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="ives_client.name" label="Name" />
                            <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                            <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                        </SimpleShowLayout>
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Taxpayer/Applicant</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <FunctionField render={renderSsnFromRecord} label="SSN" />
                            <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" />
                            <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                            {(record?.taxpayer_previous_street_name) ? (
                                <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                            ) : (<></>)}
                        </SimpleShowLayout>
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <TaxpayerEmailNotifications />
                    </Item>
                </Grid>
                <Grid item xl={3} lg={6} xs={12}>
                    <Item>
                        <Box sx={{padding: 1}}>
                            <Typography variant="h6">Request</Typography>
                        </Box>
                        <SimpleShowLayout>
                            <TextField source="user.branch.institution.shortname" label="Institution" />
                            <TextField source="user.branch.name" label="Branch" />
                            <TextField source="user.name" label="User" />
                            <TextField source="ives_request_channel" label="Channel" />
                            <ReferenceField source="ives_request_type_id" reference="ives-request-types" label="Type" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <ReferenceField source="ives_request_form_id" reference="ives-request-forms" label="Form" link="show">
                                <TextField source="name" />
                            </ReferenceField>
                            <FunctionField render={renderYearsFromRecord} label="Years" />
                            <RequestStatusField />
                            <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                            <DateField showTime={true} source="created_at" label="Created" />
                            <DateField showTime={true} source="became_invoiceable_at" label="Became Billable" />
                            <DateField showTime={true} source="updated_at" label="Updated" />
                        </SimpleShowLayout>
                    </Item>
                </Grid>
            </Grid>
        </Show>
    )
};

const ShowActions = () => (
    <TopToolbar backButton={true}>
    </TopToolbar>
);

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'IVES Request #' + record.id;
}
import {useNotify, useRecordContext} from "react-admin";
import React from "react";
import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import LoadingButton from "@mui/lab/LoadingButton";
import {pluralize} from "../../../../Components/Functions/Strings";

export const CreatePayoutsButton = ({ disabled }) => {
    const record = useRecordContext();
    const invoiceId = record?.id;
    const notify = useNotify();
    const { mutate, isLoading } = useMutation(
        ['populateInvoice'],
        () => dataProvider.createPayouts(invoiceId),
        {
            onError: (error) => {
                notify('Could not create payouts: ' + error, { type: "error"});
            },
            onSuccess: (data) => {
                const payouts = JSON.parse(data.body);
                const payoutsCount = payouts.length;
                notify('Created ' + pluralize(payoutsCount, 'Payout'), { type: "success"});
            }
        }
    );

    if (!record) {
        return '';
    }

    const handleFormSubmit = () => {
        mutate();
    }

    return (
        <LoadingButton
            loading={isLoading}
            type="submit"
            textalign="center"
            onClick={handleFormSubmit}
            startIcon={<CurrencyExchangeIcon />}
            size="small"
            sx={{lineHeight: 1.5}}
            disabled={disabled}
        >
            Create Payouts
        </LoadingButton>
    );
};
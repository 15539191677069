import {SelectInput, useGetList} from "react-admin";
import React from "react";

export const IvesRequestStatusFilterSelectInput = (props) => {
    const { data : ivesRequestStatuses, isLoading} = useGetList('ives-request-statuses');

    return (
        <SelectInput
            key={Math.random()}
            label={props.label}
            choices={ivesRequestStatuses}
            name={props.source}
            source={props.source}
            isLoading={isLoading}
            alwaysOn={true}
        />
    );
};
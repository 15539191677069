import {
    Show,
    SimpleShowLayout,
    List,
    TextField,
    DateField,
    SimpleForm,
    Edit,
    TextInput,
    Create,
    ReferenceField,
    NumberField,
    SelectInput,
    SearchInput,
    ReferenceArrayInput,
    DateInput,
    useRecordContext,
    EditButton,
    useShowController,
} from "react-admin";
import {PageTitle} from "../../../Components/PageTitle";
import {EditActions} from "../../../Components/Actions";
import React from "react";
import {useQuery} from "react-query";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {Box, Grid, Typography} from "@mui/material";
import dataProvider from "../../../Providers/dataProvider";
import {InvoiceLineItemsShow} from "../../../Components/Invoices/InvoiceLineItems";
import {Datagrid} from "../../../Components/Datagrid";
import {SendButton} from "./Invoices/SendButton";
import {ResetButton} from "./Invoices/ResetButton";
import {PopulateButton} from "./Invoices/PopulateButton";
import {InvoiceExportDetailsButton} from "../../../Components/Invoices/InvoiceExportDetailsButton";
import {MarkAsButton} from "./Invoices/MarkAsButton";
import {Item, ItemRight} from "../../../Components/Items";
import {InvoiceExportButton} from "../../../Components/Invoices/InvoiceExportButton";
import {TopToolbar} from "../../../Components/TopToolbar";
import {CreatePayoutsButton} from "./Invoices/CreatePayoutsButton";

export const InvoiceList = () => {
    return (
        <List filters={InvoiceListFilters} resource="invoices" sort={{ field: 'created_at', order: 'DESC' }} hasCreate={true} empty={false} exporter={false} >
            <Datagrid bulkActionButtons={false} rowClick="show">
                <TextField source="institution.name" label="Institution" />
                <TextField source="invoice_status.name" label="Status" sortable={false} />
                <TextField source="name" />
                <DateField source="starts_at" label="Start" />
                <DateField source="ends_at" label="End" />
                <NumberField source="amount" options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                <DateField showTime={true} source="created_at" label="Created" />
                <DateField showTime={true} source="updated_at" label="Updated"  />
            </Datagrid>
        </List>
    );
};

const InvoiceStatusFilterSelectInput = (props) => {
    const { data, isSuccess, isLoading, isError} = useQuery(
        ['getInvoiceStatuses'],
        () => dataProvider.getInvoiceStatuses('admin')
    );

    const invoiceStatuses = data?.json;

    if (isLoading || isError || !invoiceStatuses) {
        return <span key={Math.random()}>Loading...</span>
    }

    if (isSuccess) {
        return (
            <SelectInput
                key={Math.random()}
                label={props.label}
                choices={invoiceStatuses}
                name="invoice_status_id"
                source="invoice_status_id"
                isLoading={isLoading}
                alwaysOn={true}
            />
        );
    }
};

const InvoiceListFilters = [
    <SearchInput placeholder="Name" source="name" name="name" alwaysOn={true} key={Math.random()} />,
    <InvoiceStatusFilterSelectInput label="Status" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
];

export const InvoiceCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceArrayInput name="Institution" source="institution_id" reference="institutions" perPage={1000} sort={{ field: "name", order: "ASC" }}>
                <SelectInput optionText="name" required={true} style={{ width : '300px' }} source="institution_id" />
            </ReferenceArrayInput>
            <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
            <DateInput source="starts_at" name="starts_at" label="Beginning Date" />
            <DateInput source="ends_at" name="ends_at" label="Ending Date" />
        </SimpleForm>
    </Create>
);

const InvoiceShowActions = ({
        showSendButton,
        showEditButton,
        showResetButton,
        showPopulateButton,
        showExportButton,
        showExportDetailsButton,
        showMarkAsNewButton,
        showMarkAsSentButton,
        showMarkAsPaidButton,
        showCreatePayoutsButton
    }) => {
    return (
        <TopToolbar backButton={true} >
            <SendButton disabled={!showSendButton} />
            <EditButton disabled={!showEditButton} />
            <ResetButton disabled={!showResetButton} />
            <PopulateButton disabled={!showPopulateButton} />
            <InvoiceExportButton disabled={!showExportButton} />
            <InvoiceExportDetailsButton disabled={!showExportDetailsButton} />
            <CreatePayoutsButton disabled={!showCreatePayoutsButton} />
            <MarkAsButton status="New" disabled={!showMarkAsNewButton} />
            <MarkAsButton status="Sent" disabled={!showMarkAsSentButton} />
            <MarkAsButton status="Paid" disabled={!showMarkAsPaidButton} />
        </TopToolbar>
    );
}

const InvoiceSubtotals = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    /** @var record.subtotals **/
    return (
        <Box>
            <div style={{height: 203, width: '100%', overflowY: 'auto', overflowX: 'none'}}>
                <Grid container spacing={1}>
                    {record.subtotals.map((subtotal) => (
                        <Grid item xs={12} key={Math.random()}>
                            <ItemRight key={Math.random()}>
                                <Typography key={Math.random()} variant="caption" display="inline" noWrap >{subtotal.description}: </Typography>
                                <Typography key={Math.random()} variant="body2" display="inline" noWrap >{currencyFormat(subtotal.amount)}</Typography>
                            </ItemRight>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Box>
    );
}

const InvoicePayments = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    /** @var record.payments **/
    return (
        <Box>
            <div style={{height: 203, width: '100%', overflowY: 'auto', overflowX: 'none'}}>
                <Grid container spacing={1}>
                    {record.payments.map((payment) => (
                        <Grid item xs={12} key={Math.random()}>
                            <ItemRight key={Math.random()}>
                                <Typography key={Math.random()} variant="caption" display="inline" noWrap >{payment.description}: </Typography>
                                <Typography key={Math.random()} variant="body2" display="inline" noWrap >{currencyFormat(payment.amount)}</Typography>
                            </ItemRight>
                        </Grid>
                    ))}
                </Grid>
            </div>
        </Box>
    );
}

/** @var record.institution.institution_contacts **/
export const InvoiceShow = () => {
    const record = useShowController().record;
    const showSendButton = record?.invoice_status_id === 1 && record?.line_item_count > 0 && record.institution.institution_contacts.length > 0;
    const showEditButton = record?.invoice_status_id === 1;
    const showResetButton = record?.invoice_status_id === 1 && record?.line_item_count > 0;
    const showPopulateButton = record?.invoice_status_id === 1 && record?.line_item_count === 0;
    const showExportButton = record?.line_item_count > 0;
    const showExportDetailsButton = record?.line_item_count > 0;
    const showMarkAsNewButton = record?.invoice_status_id === 2;
    const showMarkAsSentButton = record?.invoice_status_id === 1;
    const showMarkAsPaidButton = record?.invoice_status_id === 2;
    const showCreatePayoutsButton = record?.invoice_status_id >= 2;
    
    let paymentTotal = 0;
    if (record?.payments) {
        for (let key in record.payments) {
            paymentTotal += record.payments[key].amount;
        }
        record.due = record.amount - paymentTotal;
    }

    return (
        <Grid container>
            <Grid item lg={12}>
                <Show
                    title={<PageTitle />}
                    actions={
                        <InvoiceShowActions
                            showSendButton={showSendButton}
                            showEditButton={showEditButton}
                            showResetButton={showResetButton}
                            showPopulateButton={showPopulateButton}
                            showExportButton={showExportButton}
                            showExportDetailsButton={showExportDetailsButton}
                            showMarkAsNewButton={showMarkAsNewButton}
                            showMarkAsSentButton={showMarkAsSentButton}
                            showMarkAsPaidButton={showMarkAsPaidButton}
                            showCreatePayoutsButton={showCreatePayoutsButton}
                        />
                    }
                >
                    <SimpleShowLayout>
                        <Grid container>
                            <Grid item lg={4}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Invoice</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Institution: </Typography>
                                            <ReferenceField source="institution_id" reference="institutions" link="show" display="inline">
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Name: </Typography>
                                            <TextField display="inline" source="name"/>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Start: </Typography>
                                            <DateField display="inline" source="starts_at" label="Start" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >End: </Typography>
                                            <DateField display="inline" source="ends_at" label="End" />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Status: </Typography>
                                            <ReferenceField source="invoice_status_id" reference="invoice-statuses" display="inline">
                                                <TextField source="name" />
                                            </ReferenceField>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Typography variant="caption" display="inline" noWrap >Created: </Typography>
                                            <DateField showTime={true} display="inline" source="created_at" label="Created" />
                                            &nbsp;&nbsp;-&nbsp;&nbsp;<Typography variant="caption" display="inline" noWrap >Updated: </Typography>
                                            <DateField showTime={true} display="inline" source="updated_at" label="Updated" />
                                        </Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <Grid container spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Summary</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <InvoiceSubtotals />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemRight>
                                            <Typography variant="caption" display="inline" noWrap style={{fontWeight: 'bold'}}>Total: </Typography>
                                            <NumberField source="amount" display="inline" style={{fontWeight: 'bold'}} options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                                        </ItemRight>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item lg={4}>
                                <Grid container spacing={1} alignItems="stretch">
                                    <Grid item xs={12}>
                                        <Typography variant="h6">Payments</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <InvoicePayments />
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ItemRight>
                                            <Typography variant="caption" display="inline" noWrap style={{fontWeight: 'bold'}}>Total Due: </Typography>
                                            <NumberField source="due" style={{fontWeight: 'bold'}} options={{style: 'currency', currency: 'USD', minimumFractionDigits: 2}} />
                                        </ItemRight>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </SimpleShowLayout>
                </Show>
            </Grid>
            <Grid item lg={12}>
                <InvoiceLineItemsShow role="admin" />
            </Grid>
        </Grid>
    );
};

export const InvoiceEdit = () => (
    <Edit title={<PageTitle />} mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
        <SimpleForm>
            <TextInput source="name" required={true} name={"name"} style={{ width : '300px' }} />
            <Grid container>
                <Grid item xl={12}>
                    <DateInput source="starts_at" required={true} name={"starts_at"} label="Start" style={{ width : '300px' }} />
                </Grid>
                <Grid item xl={12}>
                    <DateInput source="ends_at" required={true} name={"ends_at"} label="End" style={{ width : '300px' }} />
                </Grid>
                <Grid item xl={3} textAlign="center">
                    <Typography variant="h6" sx={{color: "red"}} border={1} borderColor="red">Changing a date will reset all line items</Typography>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
);
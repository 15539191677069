import {useRecordContext} from "react-admin";
import React from "react";
import {Box, Button, Grid, Typography} from "@mui/material";
import {CustomTab, CustomTabPanel, CustomTabProps, CustomTabs} from "./Tab";
import {Item} from "./Items";
import {FormattedResultsFormSwitches} from "../Pages/User/IVES/Components/FormattedResultsFormSwitches";
import {Accordion, AccordionDetails, AccordionSummary} from "./Accordion";

export const IvesResults = () => {
    const record = useRecordContext();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const pdfFilename = 'result.pdf';
    const pdfString = record.ives_request_results_merged_pdf;

    const htmlFilename = 'result.html';
    const htmlString = btoa(record.ives_request_results_merged_html);

    return (
        <Box sx={{ width: '100%', backgroundColor: 'background.paper' }}>
            <CustomTabs value={value} onChange={handleChange} >
                <CustomTab label="Report" {...CustomTabProps(0)} />
                <CustomTab label="Downloads" {...CustomTabProps(1)} />
                <CustomTab label="Interactive (Beta)" {...CustomTabProps(2)} />
            </CustomTabs>
            <Box>
                <CustomTabPanel value={value} index={0} >
                    <MergedIvesResultView />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                    <a download={pdfFilename} href={`data:application/pdf;base64,${pdfString}`} ><Button variant="outlined">Download PDF</Button></a><br/><br/>
                    <a download={htmlFilename} href={`data:text/plain;base64,${htmlString}`} ><Button variant="outlined">Download HTML</Button></a><br/><br/>
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2} >
                    <Typography variant="body2">Currently only available for Wage and Income results<br /><br /></Typography>
                    <FormattedIvesResultView record={record} />
                </CustomTabPanel>
            </Box>
        </Box>
    )
}

const MergedIvesResultView = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    const {ives_request_results_merged_pdf} = record;

    if (!ives_request_results_merged_pdf) {
        return '';
    }

    return (
        <Item>
            <embed
                key={Math.random()}
                style={{ height: 500, overflow: 'scroll' }}
                src={`data:application/pdf;base64,${ives_request_results_merged_pdf}`}
                type="application/pdf" width="100%"
            ></embed>
        </Item>
    );
}

const FormattedIvesResultView = ({ record }) => {
    const [allForms, setAllForms] = React.useState([]);
    const [selectedForms, setSelectedForms] = React.useState('');

    if (
        !record ||
        !(record.ives_request_status === 'Complete' || record.ives_request_status?.name === 'Complete') ||
        !record.ives_request_results_formatted ||
        record.ives_request_results_formatted.data.length === 0
    ) {
        return (
            <Typography variant="body2" color="textSecondary">Interactive results not available</Typography>
        );
    }

    const {ives_request_results_formatted} = record;

    let resultsArray = [];

    for (let key in ives_request_results_formatted.data) {
        resultsArray[key] = ives_request_results_formatted.data[key];
    }

    if (allForms.length === 0) {
        setAllForms(ives_request_results_formatted.forms.map((form) => { return form; }));
        let tempSelectedForms = {};
        ives_request_results_formatted.forms.forEach((form) => { tempSelectedForms[form] = true; })
        setSelectedForms(tempSelectedForms);
    }

    return (
        <Item key="formatted-ives-results-view">
            <Grid container padding={1}>
                <Grid item sm={12} md={3} lg={2}>
                    <FormattedResultsFormSwitches allForms={allForms} selectedForms={selectedForms} setSelectedForms={setSelectedForms} />
                </Grid>
                <Grid item sm={12} md={9} lg={10}>
                    {
                        resultsArray.map((content, year) => {
                            const ariaControls= "panel" + year + "-content";
                            const id = "panel" + year + "-header";
                            return (
                                <>
                                    <Accordion>
                                        <AccordionSummary
                                            aria-controls={ariaControls}
                                            id={id}
                                            sx={{flexDirection: 'row-reverse'}}
                                        >
                                            <Typography>{year}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails key={'formatted-result-year-' + {year}}>
                                            <Typography>
                                                {
                                                    content.map(form => {
                                                        if (!selectedForms[form.form]) {
                                                            return '';
                                                        }
                                                        const subAriaControls= "panel" + form.title + "-content";
                                                        const subId = "panel" + form.title + "-header";
                                                        return (
                                                            <>
                                                                <Accordion>
                                                                    <AccordionSummary
                                                                        aria-controls={subAriaControls}
                                                                        id={subId}
                                                                        sx={{flexDirection: 'row-reverse'}}
                                                                    >
                                                                        <Typography>{form.title}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <Typography>
                                                                            <div dangerouslySetInnerHTML={{__html: form.content}}/>
                                                                        </Typography>
                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </Typography>
                                        </AccordionDetails>
                                    </Accordion>
                                </>
                            )
                        })
                    }
                </Grid>
            </Grid>
        </Item>
    );
}
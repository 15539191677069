import {
    Show,
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    SimpleList,
    useShowController,
    SelectColumnsButton,
    BooleanInput,
} from "react-admin";
import { SimpleShowLayout, TextField, DateField, ReferenceField } from "react-admin";
import React from "react";
import {Box, Breadcrumbs, Grid, Typography, useMediaQuery} from "@mui/material";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord, renderSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {renderIvesClientAddress, renderTaxpayerCurrentAddress, renderTaxpayerPreviousAddress} from "../../../Components/Functions/Address";
import {renderIvesClientPhoneNumber} from "../../../Components/Functions/PhoneNumber";
import {SendTaxpayerEmailNotificationButton} from "../../User/IVES/Components/SendTaxpayerEmailNotificationButton";
import {WithdrawButton} from "../../User/IVES/Components/WithdrawButton";
import {renderYearsForListFromRecord, renderYearsFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {renderTaxpayerNameFromRecord} from "../../../Components/Functions/TaxpayerName";
import {PageTitle} from "../../../Components/PageTitle";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {Item} from "../../../Components/Items";
import {getDatagridRowTextColor} from "../../../Components/Functions/DatagridRowSx";
import {Accordion, AccordionSummary, AccordionDetails} from '../../../Components/Accordion.js';
import {TaxpayerEmailNotifications} from "../../../Components/IvesRequestTaxpayerEmailNotifications";
import {IvesResults} from "../../../Components/IvesResults";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_street_name **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.taxpayer_previous_street_name **/
/** @var record.taxpayer_previous_city_name **/
/** @var record.taxpayer_previous_state_abbreviation **/
/** @var record.taxpayer_previous_zip **/
/** @var record.ives_request_status **/
/** @var record.ives_request_years **/
/** @var record.ives_client **/
/** @var record.ives_client.city_name **/
/** @var record.ives_client.state_abbreviation **/
/** @var record.ives_client.zip **/
/** @var record.ives_requests **/
/** @var ives_request.ives_request_status **/
/** @var ives_request.ives_request_status.name **/

const ListActions = ({ isSmall }) => {
    if (isSmall) {
        return false;
    }

    return (
        <TopToolbar>
            <SelectColumnsButton />
        </TopToolbar>
    );
}

export const IvesRequestList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={filters}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="IVES Requests" />}
            resource="ives-requests"
            sort={{ field: 'updated_at', order: 'DESC' }}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={record => record.user.branch.institution.short_name}
                    tertiaryText={record => record.updated_at}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show">
                    <TextField source="id" label="ID" />
                    <TextField source="user.branch.institution.short_name" label="Institution" sortable={false} />
                    <TextField source="user.branch.name" label="Branch" sortable={false} />
                    <TextField source="user.name" label="User" sortable={false} />
                    <FunctionField source="ives_client.name" label="Client" sortable={false} render={(record) => record.ives_client.name.substring(0,20)} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" />
                    <TextField source="ives_request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsForListFromRecord} label="Years" textAlign="center" sortable={false} />
                    <TextField source="ives_request_status.name" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created"  />
                    <DateField showTime={true} source="updated_at" label="Updated"  />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    /** @var record.ives_request_form **/
    /** @var record.ives_request_form.name **/
    const lastFour = record.taxpayer_ssn.substring(5, 9);
    const form = record.ives_request_form.name === "ALL WAGE AND INCOME (ALL W2 and ALL 1099)" ? "ALL" : record.ives_request_form.name;
    const status = record.ives_request_status.name;
    const yearCount = record.ives_request_years.length;
    let years = yearCount + ' yr';
    if (yearCount > 1) { years += 's';}

    const rowColor = getDatagridRowTextColor(record);

    return (
        <Typography variant="body2" sx={{ color: rowColor}}>
            {lastFour + " - " + form + " - " + years}
            {" - " + status}
        </Typography>
    );
};

function shouldShowTetitos() {
    const user = JSON.parse(localStorage.getItem("user")) || {id: -1};

    return user.id === 1;
}

const filters = [
    <SearchInput placeholder="SSN" source="taxpayer_ssn" name="taxpayer_ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
    <SearchInput placeholder={TrackingNumberStringForIndustry()} source="tracking_number" name="tracking_number" alwaysOn={true} key={Math.random()} />,
    <IvesRequestFormFilterSelectInput source="ives_request_form_id" label="Form" alwaysOn={true} key={Math.random()} />,
    <IvesRequestStatusFilterSelectInput source="ives_request_status_id" label="Status" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
    <BooleanInput label="Hide Failed" alwaysOn={true} source="should_hide_failed" name="should_hide_failed" sx={{ padding: "12px" }} />,
    <BooleanInput label="Hide Expired" alwaysOn={true} source="should_hide_expired" name="should_hide_expired" sx={{ padding: "12px" }} />,
    shouldShowTetitos() ? <BooleanInput label="Tetitos Only" alwaysOn={true} source="should_show_only_tetitos" name="should_show_only_tetitos" sx={{ padding: "12px" }} /> : <></>
];

export const IvesRequestShow = () => {
    const record = useShowController()?.record;
    const { isFetching } = useShowController();

    const ShowWrapper = ({ children }) => (
        <Box sx={{ width: "100%" }}>
            {children}
        </Box>
    );

    const showResults = !(isFetching || !record || record.ives_request_status.name !== 'Complete');

    return (
        <Show title={<ShowTitle />} actions={<ShowActions />} component={ShowWrapper}>
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Request</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={1} direction="row" justifyContent="space-evenly" alignItems="stretch" sx={{padding: 1}}>
                        <Grid item xl={12} lg={12} xs={12}>
                            <Item>
                                <SimpleShowLayout>
                                    <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                                        <ReferenceField source="user.branch.institution_id" reference="institutions" label="Institution" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="user.branch_id" reference="branches" label="Branch" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                        <ReferenceField source="user.id" reference="users" label="User" link="show">
                                            <TextField source="name" />
                                        </ReferenceField>
                                    </Breadcrumbs>
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Client</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="ives_client.name" label="Name" />
                                    <FunctionField render={renderIvesClientPhoneNumber} label="Phone" />
                                    <FunctionField render={renderIvesClientAddress} label="Address" component="pre" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Taxpayer</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <FunctionField render={renderSsnFromRecord} label="SSN" />
                                    <FunctionField render={renderTaxpayerNameFromRecord} label="Taxpayer" component="pre" />
                                    <FunctionField render={renderTaxpayerCurrentAddress} label="Address" component="pre" />
                                    {(record?.taxpayer_previous_street_name) ? (
                                        <FunctionField render={renderTaxpayerPreviousAddress} label="Additional Address" component="pre" />
                                    ) : (<></>)}
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <TaxpayerEmailNotifications />
                            </Item>
                        </Grid>
                        <Grid item xl={3} lg={6} xs={12}>
                            <Item>
                                <Box sx={{padding: 1}}>
                                    <Typography variant="h6">Status</Typography>
                                </Box>
                                <SimpleShowLayout>
                                    <TextField source="ives_request_type.name" label="Type" />
                                    <FunctionField render={renderFormFromRecord} label="Form" />
                                    <FunctionField render={renderYearsFromRecord} label="Years" />
                                    <TextField source="ives_request_status.name" label="Status" sx={{ color: getDatagridRowTextColor(record), fontWeight: 'bold' }} />
                                    <TextField source="tracking_number" label={TrackingNumberStringForIndustry()} />
                                    <TextField source="irs_client_document_id" label="IRS Client Doc ID" />
                                    <TextField source="irs_transaction_id" label="IRS Transaction ID" />
                                    <DateField showTime={true} source="created_at" label="Created" />
                                    <DateField showTime={true} source="became_invoiceable_at" label="Became Billable" />
                                    <DateField showTime={true} source="updated_at" label="Updated" />
                                </SimpleShowLayout>
                            </Item>
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion>
            <Accordion disabled={!showResults} defaultExpanded={showResults}>
                <AccordionSummary >
                    <Typography>Results</Typography>
                </AccordionSummary>
                {
                    (showResults) ? (
                        <AccordionDetails>
                            <IvesResults />
                        </AccordionDetails>
                    ) : (<></>)
                }
            </Accordion>
        </Show>
    )
};

const ShowTitle = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    return 'IVES Request #' + record.id;
}

const ShowActions = () => (
    <TopToolbar backButton={true}>
        <WithdrawButton role="admin" />
        <SendTaxpayerEmailNotificationButton role="admin" />
    </TopToolbar>
);
import {List, Edit, useRecordContext, BooleanInput, Toolbar, SaveButton} from "react-admin";
import { TextField, SimpleForm, TextInput } from "react-admin";
import {Datagrid} from "../../../Components/Datagrid";
import {TopToolbar} from "../../../Components/TopToolbar";
import {useCacheBuster} from "react-cache-buster";
import {Button} from "@mui/material";

export const SettingList = () => {
    const { checkCacheStatus } = useCacheBuster();

    const update = () => {
        checkCacheStatus();
    }

    return (
        <>
            <Button onClick={update} >Update UI</Button>
            <List resource="settings" exporter={false} actions={false}>
                <Datagrid bulkActionButtons={false} rowClick="edit">
                    <TextField source="name" sortable={false} />
                    <TextField source="type" sortable={false} />
                    <SettingValueListField source="value" sortable={false} />
                </Datagrid>
            </List>
        </>
    );
}

const SettingValueListField = () => {
    const record = useRecordContext();
    /** @var record.value */
    /** @var record.type */
    let returnString = record.value;

    if (record.type.toLowerCase() === "boolean") {
        if (record.value === "1") {
            returnString = "True";
        } else {
            returnString = "False";
        }
    }
    return <span>{returnString}</span>;
};

export const SettingEdit = () => (
    <Edit mutationMode="pessimistic" actions={<EditActions />} redirect="show" >
        <SimpleForm toolbar={<EditToolbar />}>
            <TextField source="name" name={"name"} style={{ width : '300px' }} />
            <SettingValueEditField />
        </SimpleForm>
    </Edit>
);

const SettingValueEditField = () => {
    let record = useRecordContext();
    /** @var record.value */
    /** @var record.type */
    if (record.type.toLowerCase() !== "boolean") {
        return (<TextInput name="value" source={record.value} />);
    }

    const formatter = value => {
        return value === "1";
    };

    const parser = value => {
        return value ? "1" : "0";
    };

    return (<BooleanInput label="True" source={record.value} format={formatter} parse={parser} name="value" options={{}} />);
};

const EditActions = () => (
    <TopToolbar backButton={true}>
    </TopToolbar>
);

const EditToolbar = props => (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
);
import * as React from 'react';
import { Box, Card, Typography } from '@mui/material';
import {Link} from "react-admin";

const MainBox = () => (
    <Box display="flex">
        <Box flex="1">
            <Typography variant="h5" component="h2" gutterBottom sx={{ color: "white" }}>
                Welcome to the <span style={ { color: "white" }} >True</span><span style={ { color: "#a0dc29" }} >Data</span><span style={ { color: "#247dd4" }} >.ai</span> Admin Panel
            </Typography>
            <Box maxWidth="40em">
                <Typography variant="body1" component="p" gutterBottom sx={{ color: "white" }}>
                    Provider of instant, 100% accurate, taxpayer transcripts
                </Typography>
            </Box>
        </Box>
    </Box>
);

const Welcome = () => {
    const user = JSON.parse(localStorage.getItem("user")) || {id: -1};

    return (
        <Card
            sx={{
                background: 'linear-gradient(to right, #353b46 0%, #196bb2 35%), linear-gradient(to bottom, #353b46 0%, #196bb2 50%), #353b46',
                color: '#fff',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}
        >
            {user.id === 1 ? (
                <Link to="requests">
                    <MainBox />
                </Link>
            ) : (
                <MainBox />
            )}
        </Card>
    );
};

export default Welcome;

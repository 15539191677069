import {useMutation} from "react-query";
import dataProvider from "../../../../Providers/dataProvider";
import {Box, CircularProgress, Collapse, Grid, IconButton, Paper, Typography} from "@mui/material";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {Button} from "ra-ui-materialui";
import dayjs from "dayjs";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export const RevenueByMonth = () => {
    const [month, setMonth] = React.useState(dayjs());
    const [report, setReport] = React.useState(null);

    const { mutate, isLoading } = useMutation(
        ['getIvesRequestsByDay'],
        () => dataProvider.getRevenueByMonth(dayjs(month).format('YYYY-MM')),
        {
            onSuccess: (data) => {
                setReport(data?.json);
            }
        }
    );

    const monthChanged = (value) => {
        const newDate = dayjs(value.toString());
        setMonth(newDate.format("YYYY-MM"));
    }

    const getReport = () => {
        mutate();
    }

    const currentYear = dayjs();

    return (
        <Paper elevation={2}>
            <Grid container direction="column" justifyContent="flex-start" alignItems="center">
                <Grid item xl={12}>
                    <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                        <Grid item xl={9}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Month"
                                    maxDate={currentYear}
                                    openTo="month"
                                    views={['year', 'month']}
                                    yearsOrder="desc"
                                    sx={{ minWidth: 250 }}
                                    onChange={monthChanged}
                                    defaultValue={dayjs()}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xl={3}>
                            <Button label="Apply" onClick={getReport} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xl={12}>
                    { isLoading ? (
                        <CircularProgress />
                    ) : (
                        <RevenueByMonthTable report={report} />
                    )}
                </Grid>
            </Grid>
        </Paper>
    )
}

function RevenueByMonthTable(props) {
    function currencyFormat(num) {
        return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    function TableCellHead(props) {
        const label = props.children;
        const align = props.align;
        const sx = props.sx;
        const width = props.width;

        return (
            <TableCell align={align} sx={sx} width={width}>
                <Typography variant="h7" gutterBottom component="div" sx={{ fontWeight: "bold" }}>{label}</Typography>
            </TableCell>
        )
    }

    function Row(props) {
        const institution = props.institution;
        const [open, setOpen] = React.useState(false);

        return (
            <React.Fragment>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                    <TableCell sx={{ paddingLeft: 0, paddingRight: 0 }}>
                        <IconButton
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {institution.name}
                    </TableCell>
                    <TableCell align="right">{institution.count}</TableCell>
                    <TableCell align="right">{currencyFormat(institution.revenue)}</TableCell>
                    <TableCell align="right">{currencyFormat(institution.tetitos_revenue)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0, paddingLeft: 0, paddingRight: 0, alignContent: "right" }} colSpan={5}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box sx={{ margin: 0 }}>
                                <Table size="small" aria-label="purchases" sx={{ minWidth: 650 }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCellHead width={50} />
                                            <TableCellHead width={300}>Client</TableCellHead>
                                            <TableCellHead align="right" width={100}>Requests</TableCellHead>
                                            <TableCellHead align="right" width={100}>Revenue</TableCellHead>
                                            <TableCellHead align="right" width={100}></TableCellHead>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {institution.clients.map((clientRow) => (
                                            <TableRow key={clientRow.id}>
                                                <TableCell component="th" scope="row" />
                                                <TableCell>
                                                    {clientRow.name}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {clientRow.count}
                                                </TableCell>
                                                <TableCell align="right">
                                                    {currencyFormat(clientRow.revenue)}
                                                </TableCell>
                                                <TableCell />
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    const report = props.report;

    if (!report) {
        return (<>No data</>);
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small" sx={{ minWidth: 650 }}>
                <TableHead>
                    <TableRow>
                        <TableCell width={50} />
                        <TableCellHead width={300}>Institution</TableCellHead>
                        <TableCellHead align="right" width={100}>Count</TableCellHead>
                        <TableCellHead align="right" width={100}>Revenue</TableCellHead>
                        <TableCellHead align="right" width={100}>Tetitos</TableCellHead>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {report.institutions.map((institution) => (
                        <Row key={institution.id} institution={institution} />
                    ))}
                </TableBody>
            </Table>
            <Table aria-label="collapsible table" size="small" sx={{ minWidth: 650 }}>
                <TableBody>
                    <TableRow>
                        <TableCellHead align="right" width={450}>Totals:</TableCellHead>
                        <TableCellHead align="right" width={100}>{currencyFormat(report.total)}</TableCellHead>
                        <TableCellHead align="right" width={100}>{currencyFormat(report.tetitos_total)}</TableCellHead>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}
import {Chip, Typography} from "@mui/material";
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import {getDatagridRowTextColor} from "./DatagridRowSx";

export const renderYearsForListFromRecord = (record) => {
    if (!record) return '';

    /** @var record.request_years */
    /** @var record.ives_request_years */
    /** @var record.expanded_ives_request_years */
    if (
        (record?.request_years && record.request_years.length > 0) ||
        (record?.ives_request_years && record.ives_request_years.length > 0) ||
        (record?.expanded_ives_request_years && record.expanded_ives_request_years.length > 0)
    ) {
        let yearsObject;
        if (record?.request_years) {
            yearsObject = record.request_years;
        } else if (record?.ives_request_years) {
            yearsObject = record.ives_request_years;
        } else {
            yearsObject = record.expanded_ives_request_years;
        }

        let chipLabel = yearsObject.length + ' yr';
        if (yearsObject.length > 1) {
            chipLabel = chipLabel + 's';
        }

        const years = yearsObject.map(
            t => t.year
        ).reduce(
            (prev, curr) => [prev, ', ', curr]
        );

        const color = getDatagridRowTextColor(record);

        return (
            <Tooltip title={years} >
                <Chip
                    label={chipLabel}
                    size="small"
                    variant="outlined"
                    style={{
                        borderColor: color,
                        color: color,
                        width: 60
                    }}
                />
            </Tooltip>
        );
    }

    return '';
}

export const renderYearsFromRecord = (record) => {
    if (!record) return '';

    /** @var record.request_years */
    /** @var record.ives_request_years */
    /** @var record.expanded_ives_request_years */
    if (
        (record?.request_years && record.request_years.length > 0) ||
        (record?.ives_request_years && record.ives_request_years.length > 0) ||
        (record?.expanded_ives_request_years && record.expanded_ives_request_years.length > 0)
    ) {
        let yearsObject;
        if (record?.request_years) {
            yearsObject = record.request_years;
        } else if (record?.ives_request_years) {
            yearsObject = record.ives_request_years;
        } else {
            yearsObject = record.expanded_ives_request_years;
        }

        return (
            <Typography variant="body2">
                {
                    yearsObject.map(
                        t => <span key={Math.random()}>{t.year}</span>
                    ).reduce(
                        (prev, curr) => [prev, ', ', curr]
                    )
                }
            </Typography>
        );
    }

    return '';
}
import {ItemCenter} from "../../../Components/Items";
import React from "react";
import {IvesRequestsByMonth} from "./Reports/IvesRequestsByMonth";
import {IvesRequestsByDay} from "./Reports/IvesRequestsByDay";
import {Typography} from "@mui/material";
import {Accordion, AccordionSummary, AccordionDetails} from '../../../Components/Accordion.js';
import {RevenueByMonth} from "./Reports/RevenueByMonth";

export const ReportsList = () => {
    return (
        <ItemCenter sx={{ marginTop: 1 }}>
            <Accordion>
                <AccordionSummary >
                    <Typography>Requests By Month</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <IvesRequestsByMonth />
                </AccordionDetails>
            </Accordion>
            <Accordion>
                <AccordionSummary >
                    <Typography>Requests By Day</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <IvesRequestsByDay />
                </AccordionDetails>
            </Accordion>
            <Accordion defaultExpanded>
                <AccordionSummary >
                    <Typography>Revenue By Month</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <RevenueByMonth />
                </AccordionDetails>
            </Accordion>
        </ItemCenter>
    )
}
import {
    List,
    SearchInput,
    useRecordContext,
    FunctionField,
    SimpleList,
    SelectColumnsButton,
    BooleanInput, useRedirect, useGetOne,
} from "react-admin";
import { TextField, DateField } from "react-admin";
import React from "react";
import {Typography, useMediaQuery} from "@mui/material";
import {IvesRequestFormFilterSelectInput} from "../../../Components/Filters/IvesRequestFormFilterSelectInput";
import {IvesRequestStatusFilterSelectInput} from "../../../Components/Filters/IvesRequestStatusFilterSelectInput";
import {InstitutionFilterSelectInput} from "../../../Components/Filters/InstitutionFilterSelectInput";
import {formatSocialSecurityNumber, parseSocialSecurityNumber, renderPartialSsnFromRecord} from "../../../Components/Functions/SocialSecurityNumber";
import {renderYearsForListFromRecord} from "../../../Components/Functions/Years";
import {renderFormFromRecord} from "../../../Components/Functions/Form";
import {PageTitle} from "../../../Components/PageTitle";
import {DatagridConfigurable} from "../../../Components/DatagridConfigurable";
import {TrackingNumberStringForIndustry} from "../../../Components/Functions/Labels";
import {TopToolbar} from "../../../Components/TopToolbar";
import {getDatagridRowTextColor} from "../../../Components/Functions/DatagridRowSx";
import {useParams} from "react-router-dom";

/** @var record.taxpayer_ssn **/
/** @var record.taxpayer_street_name **/
/** @var record.taxpayer_city_name **/
/** @var record.taxpayer_state_abbreviation **/
/** @var record.taxpayer_zip **/
/** @var record.taxpayer_previous_street_name **/
/** @var record.taxpayer_previous_city_name **/
/** @var record.taxpayer_previous_state_abbreviation **/
/** @var record.taxpayer_previous_zip **/
/** @var record.request_status **/
/** @var record.request_years **/
/** @var record.client **/
/** @var record.client.city_name **/
/** @var record.client.state_abbreviation **/
/** @var record.client.zip **/
/** @var record.requests **/
/** @var request.request_status **/
/** @var request.request_status.name **/

const ListActions = ({ isSmall }) => {
    if (isSmall) {
        return false;
    }

    return (
        <TopToolbar>
            <SelectColumnsButton />
        </TopToolbar>
    );
}

export const RequestList = () => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    return (
        <List
            filters={filters}
            actions={<ListActions isSmall={isSmall} />}
            title={<PageTitle label="Requests" />}
            resource="requests"
            sort={{ field: 'updated_at', order: 'DESC' }}
            queryOptions={{refetchOnWindowFocus: true}}
        >
            {isSmall ? (
                <SimpleList
                    hasBulkActions={false}
                    linkType="show"
                    primaryText={<SimpleListLabel />}
                    secondaryText={record => record.user.branch.institution.short_name}
                    tertiaryText={record => record.updated_at}
                />
            ) : (
                <DatagridConfigurable bulkActionButtons={false} rowClick="show">
                    <FunctionField source="id" label="ID" render={(record) => (record.id > 9000000 ? record.id - 9000000 : record.id - 8000000)} />
                    <TextField source="user.branch.institution.short_name" label="Institution" sortable={false} />
                    <TextField source="user.branch.name" label="Branch" sortable={false} />
                    <TextField source="user.name" label="User" sortable={false} />
                    <FunctionField source="client.name" label="Client" sortable={false} render={(record) => record.client.name.substring(0,20)} />
                    <FunctionField render={renderPartialSsnFromRecord} label="SSN" />
                    <TextField source="request_type.name" label="Type" sortable={false} />
                    <FunctionField render={renderFormFromRecord} label="Form" sortable={false} />
                    <FunctionField render={renderYearsForListFromRecord} label="Years" textAlign="center" sortable={false} />
                    <TextField source="request_status.name" label="Status" sortable={false} />
                    <DateField showTime={true} source="created_at" label="Created"  />
                    <DateField showTime={true} source="updated_at" label="Updated"  />
                </DatagridConfigurable>
            )}
        </List>
    );
};

const SimpleListLabel = () => {
    const record = useRecordContext();

    if (!record) {
        return '';
    }

    /** @var record.request_form **/
    /** @var record.request_form.name **/
    const lastFour = record.taxpayer_ssn.substring(5, 9);
    const form = record.request_form.name === "ALL WAGE AND INCOME (ALL W2 and ALL 1099)" ? "ALL" : record.request_form.name;
    const status = record.request_status.name;
    const yearCount = record.request_years.length;
    let years = yearCount + ' yr';
    if (yearCount > 1) { years += 's';}

    const rowColor = getDatagridRowTextColor(record);

    return (
        <Typography variant="body2" sx={{ color: rowColor}}>
            {lastFour + " - " + form + " - " + years}
            {" - " + status}
        </Typography>
    );
};

function shouldShowTetitos() {
    const user = JSON.parse(localStorage.getItem("user")) || {id: -1};

    return user.id === 1;
}

const filters = [
    <SearchInput placeholder="SSN" source="taxpayer_ssn" name="taxpayer_ssn" alwaysOn={true} format={formatSocialSecurityNumber} parse={parseSocialSecurityNumber} key={Math.random()} />,
    <SearchInput placeholder={TrackingNumberStringForIndustry()} source="tracking_number" name="tracking_number" alwaysOn={true} key={Math.random()} />,
    <IvesRequestFormFilterSelectInput source="request_form_id" label="Form" alwaysOn={true} key={Math.random()} />,
    <IvesRequestStatusFilterSelectInput source="request_status_id" label="Status" alwaysOn={true} key={Math.random()} />,
    <InstitutionFilterSelectInput label="Institution" alwaysOn={true} key={Math.random()} />,
    <BooleanInput label="Hide Failed" alwaysOn={true} source="should_hide_failed" name="should_hide_failed" sx={{ padding: "12px" }} />,
    <BooleanInput label="Hide Expired" alwaysOn={true} source="should_hide_expired" name="should_hide_expired" sx={{ padding: "12px" }} />,
    shouldShowTetitos() ? <BooleanInput label="Tetitos Only" alwaysOn={true} source="should_show_only_tetitos" name="should_show_only_tetitos" sx={{ padding: "12px" }} /> : <></>
];

export const RequestShow = () => {
    const redirect = useRedirect();
    const { id: rawId } = useParams();
    const id = rawId > 9000000 ? rawId - 9000000 : rawId - 8000000;
    const resource = rawId > 9000000 ? 'ives-requests' : 'expanded-ives-requests';
    const redirectTo = rawId > 9000000 ? '/ives-requests/' + id + '/show' : '/expanded-ives-requests/' + id + '/show';

    useGetOne(
        resource,
        { id },
        { onSettled: () => redirect(redirectTo + '?bbr=requests') }
    );
}
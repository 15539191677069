import {Box, Grid, Typography} from "@mui/material";
import {ArrayField, Datagrid, DateField, EmailField, SimpleShowLayout, TextField} from "react-admin";
import {Accordion, AccordionDetails, AccordionSummary} from "./Accordion";
import React from "react";

export const TaxpayerEmailNotifications = () => {
    return (
        <>
            <Box sx={{padding: 1}}>
                <Typography variant="h6">E-Mail Notifications</Typography>
            </Box>
            <SimpleShowLayout>
                <EmailField source="taxpayer_email" label="Email" />
                <Box sx={{maxHeight: 275, overflowY: 'auto'}}>
                    <ArrayField source="taxpayer_email_notifications" label={false} >
                        <Datagrid
                            bulkActionButtons={false}
                            empty={<Typography variant="body2">No E-Mail Notifications Sent</Typography>}
                        >
                            <Accordion>
                                <AccordionSummary
                                    sx={{
                                        '& .MuiAccordionSummary-content': {
                                            margin: 0,
                                            minHeight: 0,
                                        },
                                        '& .MuiButtonBase-root': {
                                            margin: 0,
                                            minHeight: 0,
                                        }
                                    }}
                                >
                                    <Grid container spacing={0} direction="row" justifyContent="space-evenly" alignItems="stretch">
                                        <Grid item xs={8} alignItems="left">
                                            <DateField source="updated_at" showTime={true} label="Date/Time" options={{dateStyle: "short", timeStyle: "short"}} />
                                        </Grid>
                                        <Grid item xs={4} alignItems="right">
                                            <TextField source="taxpayer_email_notification_status.name" label="Last Status" />
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails sx={{ padding: 0, margin: 0 }}>
                                    <ArrayField source="taxpayer_email_notification_events" label={false} >
                                        <Datagrid
                                            bulkActionButtons={false}
                                            empty={<Typography variant="body2">No Events</Typography>}
                                        >
                                            <DateField source="created_at" showTime={true} label="Date/Time" options={{dateStyle: "short", timeStyle: "short"}} />
                                            <TextField source="taxpayer_email_notification_event_type.name" label="Event" />
                                        </Datagrid>
                                    </ArrayField>
                                </AccordionDetails>
                            </Accordion>
                        </Datagrid>
                    </ArrayField>
                </Box>
            </SimpleShowLayout>
        </>
    );
}